import axios from "axios";

//axios.defaults.baseURL = 'https://localhost:44389/api/v1/';
// axios.defaults.baseURL = 'https://yuguo-hrms-dev.sea.sg/api/v1/';
axios.defaults.baseURL = getBaseAddress();
console.log(getBaseAddress());
let user = JSON.parse(localStorage.getItem('user'))
let token = null;
if (user != null) {
    token = user.Token
}
axios.defaults.headers.common['Authorization'] = 'Bearer  ' + token;


axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    console.log(error)
    if (error.response.status == 401 || error.response.status == 405) {
        window.location.href = '/login';
        localStorage.removeItem('user');
    }
    return Promise.reject(error);
});
function getBaseAddress() {
    // const dev = "https://yuguo-hrms-dev.sea.sg/api/v1/";
    const live = " https://yuguo-hrms.sea.sg/api/v1/";
    const local = "http://localhost:5678/api/v1/";
    if (process.env.NODE_ENV === "development") {
        // return dev;
        return local;
    }
    return live; //live url
}